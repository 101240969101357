<template>
  <el-row class="table">
    <span class="title">竞赛管理</span>
    <el-row class="row-bg" style="margin-top: 10px;" :gutter="24">
      <el-col :span="3">
        <el-input
          placeholder="输入竞赛名称"
          v-model.trim="moduleTitle"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-input
          style="width: 150px"
          placeholder="输入题库ID"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          v-model.trim="creatorName"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="modelState" placeholder="请选择模式">
          <el-option
            v-for="item in modelStateList"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="defunct" placeholder="请选择状态">
          <el-option
            v-for="item in defuncteList"
            :key="item.id"
            :label="item.state"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="10">
        <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
        <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="medium" @click="add" class="tb-button">创建竞赛</el-button>
      </el-col>

    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="ID" align="center" prop="contest_id"></el-table-column>
      <el-table-column label="竞赛名称" align="center" prop="title"></el-table-column>
      <el-table-column label="模式" align="center" >
        <template slot-scope="scope">
          <span v-if="scope.row.raceRule === 'CA'">刷题</span>
          <span v-else>{{scope.row.raceRule}}</span>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.startTime && scope.row.startTime.indexOf('0001-01-01') === -1">{{ scope.row.startTime.substring(0,19)}}</span>
          <span v-else>不限时</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.startTime && scope.row.endTime.indexOf('9999-12-31') === -1">{{ scope.row.endTime.substring(0,19)}}</span>
          <span v-else>不限时</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span
            v-text="getState(scope.row.defunct)"
            :style="'color'+':'+getStateColor(scope.row.defunct)"
          ></span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button size="small" type="primary">
              管 理
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="gotodetail(scope.row)">
                <span>管理竞赛</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.defunct === 'Y'" @click.native="updateQuestionState(scope.row,'N')">
                <span>发布竞赛</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.defunct === 'N'" @click.native="updateQuestionState(scope.row,'Y')">
                <span>下架竞赛</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="copyContest(scope.row)">
                <span>复制竞赛</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="deleteItem(scope.row)">
                <span>删除竞赛</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <add-contest :show="addcontestDialog" @updateView="getRaceContestList" :copyType="copyType" :contestId="contestId"
                 @close="addcontestDialog=false"></add-contest>
  </el-row>
</template>

<script>
  import addContest from '@/components/research/addContest'
  import {getRaceContestList, updateQuestionState} from '@/api/research/contest'

  export default {
    components: {
      addContest,
    },
    data() {
      return {
        controls: false,
        defunct: '',
        defuncteList: [
          {
            id: 'Y',
            state: '未发布'
          }, {
            id: 'N',
            state: '已发布'
          },
        ],
        modelState: '',
        modelStateList: [
          {
            name: 'ACM'
          }, {
            name: 'OI'
          }, {
            name: 'IOI'
          }, {
            name: 'CA'
          },
        ],
        addcontestDialog: false,
        dataList: [
          {
            ID: 123,
          }
        ],
        copyType: false,
        contestId: '',
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        addMoudleDialog: false,
        moduleTitle: "",
        creatorName: ""
      }
    },
    created() {
      this.getRaceContestList()
    },
    methods: {
      getState(state) {
        switch (state) {
          case 'Y':
            return '未发布'
          case 'N':
            return '已发布'
          default:
            return '未知状态'
        }
      },
      getStateColor(state) {
        switch (state) {
          case 'Y':
            return '#CE433F'
          case 'N':
            return '#4DA54D'
          default:
            return '#F5C319'
        }
      },
      gotodetail(row) {
        this.$router.push({
          path: '/research/contest-detail',
          query: {
            editData: JSON.stringify(row)
          }
        })
      },
      // 获取模板列表
      async getRaceContestList() {
        const response = await getRaceContestList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          title: this.moduleTitle,
          contest_id: this.creatorName,
          raceRule: this.modelState,
          defunct: this.defunct
        })
        this.dataList = response.body.list
        this.pagination.total = response.body.total
      },

      // 更新
      async updateQuestionState(row, defunct) {
        let jsonArray = []
        let jsonObject = {}
        jsonObject.contest_id = row.contest_id
        jsonObject.defunct = defunct
        jsonArray.push(jsonObject)
        const response = await updateQuestionState(jsonArray)
        if (response && response.state === 'success') {
          window.$msg('更新状态成功')
          this.getRaceContestList()
        }
      },

      // 重置
      reset() {
        this.modelState = ''
        this.defunct = ''
        this.moduleTitle = ''
        this.creatorName = ''
      },

      // 搜索
      search() {
        this.pagination.currentPage = 1
        this.getRaceContestList()
      },

      add() {
        this.contestId = -1
        this.copyType = false
        this.addcontestDialog = true
      },
      copyContest(row) {
        this.contestId = row.contest_id
        this.copyType = true
        this.addcontestDialog = true
      },
      async deleteContest(row) {
        let jsonArray = []
        let jsonObject = {}
        jsonObject.contest_id = row.contest_id
        jsonObject.is_delete = 1
        jsonArray.push(jsonObject)
        const response = await updateQuestionState(jsonArray)
        if (response && response.state === 'success') {
          window.$msg('删除竞赛成功')
          this.getRaceContestList()
        }
      },
      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getRaceContestList()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getRaceContestList()
      },


      // 删除
      deleteItem(row) {
        this.$confirm('是否删除当前竞赛', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            this.deleteContest(row)
          })
          .catch(() => {
            return false
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
</style>
