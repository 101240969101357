var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("竞赛管理")]),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { "margin-top": "10px" },
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入竞赛名称" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.moduleTitle,
                  callback: function ($$v) {
                    _vm.moduleTitle = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "moduleTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  placeholder: "输入题库ID",
                  onkeyup: "value=value.replace(/[^\\d]/g,'')",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.creatorName,
                  callback: function ($$v) {
                    _vm.creatorName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "creatorName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择模式" },
                  model: {
                    value: _vm.modelState,
                    callback: function ($$v) {
                      _vm.modelState = $$v
                    },
                    expression: "modelState",
                  },
                },
                _vm._l(_vm.modelStateList, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态" },
                  model: {
                    value: _vm.defunct,
                    callback: function ($$v) {
                      _vm.defunct = $$v
                    },
                    expression: "defunct",
                  },
                },
                _vm._l(_vm.defuncteList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.state, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜 索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重 置")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.add },
                },
                [_vm._v("创建竞赛")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", prop: "contest_id" },
          }),
          _c("el-table-column", {
            attrs: { label: "竞赛名称", align: "center", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "模式", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.raceRule === "CA"
                      ? _c("span", [_vm._v("刷题")])
                      : _c("span", [_vm._v(_vm._s(scope.row.raceRule))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "开始时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.startTime &&
                    scope.row.startTime.indexOf("0001-01-01") === -1
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.startTime.substring(0, 19))),
                        ])
                      : _c("span", [_vm._v("不限时")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "结束时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.startTime &&
                    scope.row.endTime.indexOf("9999-12-31") === -1
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.endTime.substring(0, 19))),
                        ])
                      : _c("span", [_vm._v("不限时")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      style:
                        "color" + ":" + _vm.getStateColor(scope.row.defunct),
                      domProps: {
                        textContent: _vm._s(_vm.getState(scope.row.defunct)),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [
                            _vm._v(" 管 理 "),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.gotodetail(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("管理竞赛")])]
                            ),
                            scope.row.defunct === "Y"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.updateQuestionState(
                                          scope.row,
                                          "N"
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("发布竞赛")])]
                                )
                              : _vm._e(),
                            scope.row.defunct === "N"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.updateQuestionState(
                                          scope.row,
                                          "Y"
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("下架竞赛")])]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.copyContest(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("复制竞赛")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deleteItem(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除竞赛")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-contest", {
        attrs: {
          show: _vm.addcontestDialog,
          copyType: _vm.copyType,
          contestId: _vm.contestId,
        },
        on: {
          updateView: _vm.getRaceContestList,
          close: function ($event) {
            _vm.addcontestDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }