var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "75%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.categoryTitle) } }),
        ]
      ),
      _c(
        "el-steps",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { active: _vm.active, "finish-status": "success" },
        },
        [
          _c("el-step", {
            staticStyle: { "font-size": "16px" },
            attrs: { title: "竞赛设置" },
          }),
          _c("el-step", { attrs: { title: "选择题目" } }),
          _c("el-step", { attrs: { title: "完成" } }),
        ],
        1
      ),
      _vm.active === 0
        ? _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "竞赛名称：", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入竞赛名称", maxlength: "20" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开放报名：", prop: "opentype" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.radioChange },
                      model: {
                        value: _vm.formData.opentype,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "opentype", $$v)
                        },
                        expression: "formData.opentype",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("不开放"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("开放报名"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("开放报名&报名审核"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.enrollTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "报名时间：", prop: "applyTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.applychecked,
                          type: "datetimerange",
                          "start-placeholder": "活动报名开始时间",
                          "end-placeholder": "活动报名结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00"],
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$forceUpdate()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.formData.applyTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "applyTime", $$v)
                          },
                          expression: "formData.applyTime",
                        },
                      }),
                      _vm.formData.guize === 4
                        ? _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "15px" },
                              model: {
                                value: _vm.applychecked,
                                callback: function ($$v) {
                                  _vm.applychecked = $$v
                                },
                                expression: "applychecked",
                              },
                            },
                            [_vm._v("不限时")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "赛制模式：", prop: "guize" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.guize,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "guize", $$v)
                        },
                        expression: "formData.guize",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("ACM")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("OI")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("IOI")]),
                      _c("el-radio", { attrs: { label: 4 } }, [_vm._v("刷题")]),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "CA表示刷题类型的竞赛，竞赛模式创建完成不可更改",
                        placement: "right",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: {
                          "font-size": "18px",
                          "margin-left": "20px",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "竞赛时间：", prop: "contestTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: _vm.checked,
                      type: "datetimerange",
                      "start-placeholder": "竞赛开始时间",
                      "end-placeholder": "竞赛结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$forceUpdate()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.contestTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contestTime", $$v)
                      },
                      expression: "formData.contestTime",
                    },
                  }),
                  _vm.formData.guize === 4
                    ? _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "15px" },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [_vm._v("不限时")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "编程语言：", prop: "language" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.formData.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "language", $$v)
                        },
                        expression: "formData.language",
                      },
                    },
                    _vm._l(_vm.languageList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "竞赛介绍：" } },
                [
                  _c("Tinymce", {
                    attrs: { width: "100%", height: 500 },
                    model: {
                      value: _vm.contestContent,
                      callback: function ($$v) {
                        _vm.contestContent = $$v
                      },
                      expression: "contestContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.active === 1
        ? _c(
            "el-row",
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 60 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("待选题目：")]
                          ),
                          _c(
                            "el-row",
                            { staticClass: "label-item-left" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: {
                                    width: "250px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { placeholder: "请输入内容" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.search.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryData.inputVal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryData, "inputVal", $$v)
                                    },
                                    expression: "queryData.inputVal",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "110px" },
                                      attrs: { slot: "prepend" },
                                      slot: "prepend",
                                      model: {
                                        value: _vm.queryData.select,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryData, "select", $$v)
                                        },
                                        expression: "queryData.select",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "问题标题",
                                          value: "1",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "问题id", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.handleSeleTag },
                                  model: {
                                    value: _vm.queryData.seleTag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryData, "seleTag", $$v)
                                    },
                                    expression: "queryData.seleTag",
                                  },
                                },
                                _vm._l(_vm.tagList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.title,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "small" },
                                  on: { click: _vm.search },
                                },
                                [_vm._v("筛 选")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.reset },
                                },
                                [_vm._v("重 置")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "label-item-left" },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleTable",
                                  staticClass: "tb-list",
                                  attrs: {
                                    data: _vm.dataList,
                                    border: "",
                                    fit: "",
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "selection-change": _vm.handleSeleChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "问题ID",
                                      align: "center",
                                      width: "200",
                                      prop: "problem_id",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "问题标题",
                                      align: "left",
                                      prop: "title",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "标签", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              !scope.row.tagList ||
                                              scope.row.tagList.length === 0
                                                ? [_c("span", [_vm._v("无")])]
                                                : _vm._l(
                                                    scope.row.tagList,
                                                    function (item) {
                                                      return _c(
                                                        "span",
                                                        { key: item.id },
                                                        [
                                                          Boolean(
                                                            item.groupInfo
                                                          )
                                                            ? [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "tag",
                                                                  style:
                                                                    "backgroundColor" +
                                                                    ":" +
                                                                    item
                                                                      .groupInfo
                                                                      .color,
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        item.title
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            : [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "tag",
                                                                  style:
                                                                    "backgroundColor" +
                                                                    ":" +
                                                                    "#ccc",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        item.title
                                                                      ),
                                                                  },
                                                                }),
                                                              ],
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1400143204
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "添加", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        scope.row.choosetype ==
                                                        0,
                                                      expression:
                                                        "scope.row.choosetype == 0",
                                                    },
                                                  ],
                                                  staticClass: "btn-span",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addQues(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        scope.row.choosetype ==
                                                        1,
                                                      expression:
                                                        "scope.row.choosetype == 1",
                                                    },
                                                  ],
                                                  staticClass: "btn-hasspan",
                                                },
                                                [_vm._v("已添加")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3996032016
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "label-item-left" },
                            [
                              _c(
                                "el-dropdown",
                                {
                                  staticClass: "tb-dropdown",
                                  staticStyle: { "margin-top": "20px" },
                                  attrs: { trigger: "click" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "info", size: "medium" } },
                                    [
                                      _vm._v(" 批量操作 "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticStyle: { padding: "10px" },
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.enableAccount.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus-outline",
                                          }),
                                          _vm._v("批量添加 "),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.disabledAccount.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                          _vm._v("批量删除 "),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.pagination.currentPage,
                              "page-sizes": _vm.pagination.pageSizes,
                              "page-size": _vm.pagination.pageSize,
                              layout: _vm.pagination.layout,
                              total: _vm.pagination.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("已选题目：")]
                          ),
                          _c(
                            "el-row",
                            { staticClass: "haschoose" },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "dragtablechoose",
                                  staticClass: "tb-list",
                                  attrs: {
                                    data: _vm.seleTopic,
                                    border: "",
                                    fit: "",
                                    "row-key": "problem_id",
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "题号", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.$index + 1) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3056706777
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "ID",
                                      align: "center",
                                      prop: "problem_id",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "标题",
                                      align: "center",
                                      prop: "title",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "操作", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("i", {
                                                staticClass: "el-icon-sort",
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  "margin-right": "10px",
                                                  cursor: "pointer",
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-delete-solid",
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteItem(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1072455018
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.active === 2
        ? _c("el-row", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                  "flex-direction": "column",
                  "margin-top": "40px",
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-circle-check",
                  staticStyle: { "font-size": "50px", color: "#0CA51A" },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-top": "20px",
                      "font-weight": "bold",
                      "font-size": "25px",
                    },
                  },
                  [_vm._v("竞赛创建成功")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "el-row",
        {
          staticClass: "footerparent",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.active == 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.pre },
                },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _vm.active == 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.next },
                },
                [_vm._v("下一步")]
              )
            : _vm._e(),
          _vm.active == 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addQuestionLibrary },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm.active == 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.finishCreate },
                },
                [_vm._v("完成")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }