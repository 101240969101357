<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    width="75%"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="categoryTitle"></span>
    </div>
    <el-steps :active="active" finish-status="success" style="margin-bottom: 15px;">
      <el-step title="竞赛设置" style="font-size: 16px">
      </el-step>
      <el-step title="选择题目">
      </el-step>
      <el-step title="完成">
      </el-step>
    </el-steps>
    <el-form v-if="active === 0" ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="竞赛名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入竞赛名称" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="开放报名：" prop="opentype">
        <el-radio-group v-model="formData.opentype" @change="radioChange">
          <el-radio :label="1">不开放</el-radio>
          <el-radio :label="2">开放报名</el-radio>
          <el-radio :label="3">开放报名&报名审核</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="enrollTime" label="报名时间：" prop="applyTime">
        <el-date-picker
          :disabled="applychecked"
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.applyTime"
          type="datetimerange"
          start-placeholder="活动报名开始时间"
          end-placeholder="活动报名结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <el-checkbox v-if="formData.guize === 4" style="margin-left: 15px;" v-model="applychecked">不限时</el-checkbox>
      </el-form-item>
      <el-form-item label="赛制模式：" prop="guize">
        <el-radio-group v-model="formData.guize">
          <el-radio :label="1">ACM</el-radio>
          <el-radio :label="2">OI</el-radio>
          <el-radio :label="3">IOI</el-radio>
          <el-radio :label="4">刷题</el-radio>
        </el-radio-group>
        <el-tooltip class="item" effect="dark" content="CA表示刷题类型的竞赛，竞赛模式创建完成不可更改" placement="right">
          <i class="el-icon-question" style="font-size: 18px;margin-left: 20px"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="竞赛时间：" prop="contestTime">
        <el-date-picker
          :disabled="checked"
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="formData.contestTime"
          type="datetimerange"
          start-placeholder="竞赛开始时间"
          end-placeholder="竞赛结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        <el-checkbox v-if="formData.guize === 4" style="margin-left: 15px;" v-model="checked">不限时</el-checkbox>
      </el-form-item>
      <el-form-item label="编程语言：" prop="language">
        <el-select v-model="formData.language" multiple filterable placeholder="请选择">
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="竞赛介绍：">
        <Tinymce v-model="contestContent" width="100%" :height="500"/>
      </el-form-item>
    </el-form>
    <el-row v-if="active ===1">
      <el-form>
        <el-row :gutter="60">
          <el-col :span="14">
            <span style="font-weight: bold;">待选题目：</span>
            <el-row class="label-item-left">
              <el-input placeholder="请输入内容" v-model="queryData.inputVal" style="width: 250px;margin-right: 20px"
                        @keyup.enter.native="search">
                <el-select style="width:110px" v-model="queryData.select" slot="prepend">
                  <el-option label="问题标题" value="1"></el-option>
                  <el-option label="问题id" value="2"></el-option>
                </el-select>
              </el-input>
              <el-select
                v-model="queryData.seleTag"
                multiple
                placeholder="请选择"
                style="width: 150px;margin-right: 20px;"
                @change="handleSeleTag"
              >
                <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.id"></el-option>
              </el-select>
              <el-button type="info" @click="search" size="small">筛 选</el-button>
              <el-button type="primary" @click="reset" size="small">重 置</el-button>
            </el-row>

            <el-row class="label-item-left">
              <el-table
                ref="multipleTable"
                :data="dataList"
                border
                fit
                highlight-current-row
                class="tb-list"
                @selection-change="handleSeleChange"
              >
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="问题ID" align="center" width="200" prop="problem_id"></el-table-column>
                <el-table-column label="问题标题" align="left" prop="title"></el-table-column>
                <el-table-column label="标签" align="center">
                  <template slot-scope="scope">
                    <template v-if="!scope.row.tagList || scope.row.tagList.length === 0">
                      <span>无</span>
                    </template>
                    <template v-else>
              <span v-for="item of scope.row.tagList" :key="item.id">
                <template v-if="Boolean(item.groupInfo)">
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+item.groupInfo.color"></span>
                </template>
                <template v-else>
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
                </template>
              </span>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="添加" align="center">
                  <template slot-scope="scope">
            <span
              class="btn-span"
              v-show="scope.row.choosetype == 0"
              @click="addQues(scope.row, scope.$index)"
            >添加</span>
                    <span class="btn-hasspan" v-show="scope.row.choosetype == 1">已添加</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>

            <el-row class="label-item-left">
              <el-dropdown trigger="click" class="tb-dropdown" style="margin-top: 20px;">
                <el-button type="info" size="medium">
                  批量操作
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu style="padding: 10px;" slot="dropdown">
                  <el-dropdown-item @click.native="enableAccount"><i class="el-icon-circle-plus-outline"></i>批量添加
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="disabledAccount"><i class="el-icon-delete"></i>批量删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>

            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :layout="pagination.layout"
              :total="pagination.total"
            ></el-pagination>
          </el-col>
          <el-col :span="10">
            <span style="font-weight: bold;">已选题目：</span>
            <el-row class="haschoose">
              <el-table
                ref="dragtablechoose"
                :data="seleTopic"
                border
                fit
                row-key="problem_id"
                highlight-current-row
                class="tb-list"
              >
                <el-table-column label="题号" align="center">
                  <template slot-scope="scope">
                    {{scope.$index + 1}}
                  </template>
                </el-table-column>
                <el-table-column label="ID" align="center" prop="problem_id"></el-table-column>
                <el-table-column label="标题" align="center" prop="title"></el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-sort" style="font-size: 16px;margin-right: 10px;cursor: pointer"></i>
                    <i class="el-icon-delete-solid" style="font-size: 16px;cursor: pointer"
                       @click="deleteItem(scope.row)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row v-if="active === 2">
      <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top: 40px;">
        <i class="el-icon-circle-check" style="font-size: 50px;color: #0CA51A"></i>
        <span style="margin-top: 20px;font-weight: bold;font-size: 25px">竞赛创建成功</span>
      </div>

    </el-row>
    <!-- footer -->
    <el-row slot="footer" class="footerparent">
      <el-button v-if="active == 1" type="primary" @click="pre" size="small">上一步</el-button>
      <el-button v-if="active == 0" type="primary" @click="next" size="small">下一步</el-button>
      <el-button v-if="active == 1" @click="addQuestionLibrary" type="primary" size="small">提交</el-button>
      <el-button v-if="active == 2" @click="finishCreate" type="primary" size="small">完成</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  /**
   * 弹出框-使用封装
   * @attribute show 弹出框状态
   * @function close 自定义弹窗关闭事件
   */

  import Sortable from 'sortablejs'
  import {langmask} from '@/utils/langmask'
  import Tinymce from '@/components/newTinymce'
  import {getQuestionList} from '@/api/research/question'
  import {getTags} from '@/api/research/tag'
  import getCourseType from '@/mixins/getCourseType'
  import {addQuestionLibrary, getQuestionByLibraryId, getContextInfo} from '@/api/research/contest'

  export default {
    components: {Sortable, Tinymce},
    mixins: [getCourseType],
    props: {
      show: {
        default: false,
        type: Boolean
      },
      contestId: {
        default: -1,
      },
      copyType: {
        default: false,
        type: Boolean
      },
    },
    watch: {
      show() {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      }
    },
    data() {
      let RichTimeVal = (rule, value, callback) => {
        if (!this.formData.applyTime || this.formData.applyTime.length === 0) {
          callback(new Error('请选择活动报名时间'))
        } else {
          callback()
        }
      };
      return {
        langmaskValue: '',
        languageList: langmask,
        contestContent: '',
        checked: false,
        applychecked: false,
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        queryData: {
          inputVal: '',
          select: '1',
          seleTag: '',
          tagId: ''
        },
        tagList: [], // 已启用的标签
        dataList: [],
        multipleSelection: [],
        active: 0,
        enrollTime: false,
        categoryTitle: '新增竞赛',
        formData: {},
        categoryList: [],
        contestInfo: {},
        newList:[],
        oldList: [],
        rules: {
          title: [
            {required: true, message: '课程类别名称不能为空', trigger: 'blur'},
            {max: 20, message: '字数限制在20个字内', trigger: 'blur'}
          ],
          opentype: {required: true, message: '开放报名不能为空', trigger: 'blur'},
          applyTime: {required: true, message: '报名时间不能为空', trigger: 'blur'},
          guize: {required: true, message: '请选择赛制模式', trigger: 'blur'},
          contestTime: {required: true, message: '请选择竞赛时间', trigger: 'blur'},
          language: {required: true, message: '请选择编程语言', trigger: 'blur'},
        }
      }
    },
    methods: {
      setSort() {
        const el = this.$refs.dragtablechoose.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
        Sortable.create(el, {
          ghostClass: 'sortable-ghost',
          setData: (dataTransfer) => {
            dataTransfer.setData('Text', '')
          },
          onEnd: evt => {
            const targetRow = this.seleTopic.splice(evt.oldIndex, 1)[0]
            this.seleTopic.splice(evt.newIndex, 0, targetRow)
            const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
            this.newList.splice(evt.newIndex, 0, tempIndex)
          }
        })
      },
      radioChange(value) {
        if (this.formData.opentype === 1) {
          this.enrollTime = false
          this.formData.applyTime = ['0001-01-01 00:00:00', '9999-12-31 23:59:59']
        } else {
          this.enrollTime = true
          if (this.categoryTitle === '新增竞赛') {
            this.formData.applyTime = []
          } else if (this.categoryTitle === '复制竞赛') {
            this.formData.applyTime = []
            if (this.contestInfo.endEnrollTime.indexOf('9999-12-31') === -1) {
              this.formData.applyTime.push(this.contestInfo.startEnrollTime)
              this.formData.applyTime.push(this.contestInfo.endEnrollTime)
            }
          }
        }
      },
      // 查看竞赛详情
      async getContextInfo() {
        const response = await getContextInfo({
          contest_id: this.contestId,
          defunct: ''
        })
        if (response && response.state === 'success') {
          this.contestInfo = response.body

          this.formData.title = this.contestInfo.title

          this.contestContent = this.contestInfo.description
          this.$forceUpdate()
          if (this.contestInfo.isPrivate === 1) {
            this.formData.opentype = 1
            this.enrollTime = false
          } else if (this.contestInfo.isPrivate === 0) {
            this.enrollTime = true
            if (this.contestInfo.isVerify === 'Y') {
              this.formData.opentype = 3
            } else if (this.contestInfo.isVerify === 'N') {
              this.formData.opentype = 2
            }
          }
          this.formData.language = this.contestInfo.langmask.split(',')

          if (this.contestInfo.endTime.indexOf('9999-12-31 23:59:59') !== -1) {
            this.checked = true
          } else {
            this.formData.contestTime.push(this.contestInfo.startTime)
            this.formData.contestTime.push(this.contestInfo.endTime)
          }

          if (this.contestInfo.endEnrollTime.indexOf('9999-12-31') !== -1) {
            if (this.contestInfo.raceRule === 'CA') {
              this.applychecked = true
            } else {
              this.applychecked = false
            }
          } else {
            this.formData.applyTime.push(this.contestInfo.startEnrollTime)
            this.formData.applyTime.push(this.contestInfo.endEnrollTime)
          }

          switch (this.contestInfo.raceRule) {
            case 'ACM':
              this.formData.guize = 1
              break;
            case 'OI':
              this.formData.guize = 2
              break;
            case 'IOI':
              this.formData.guize = 3
              break;
            case 'CA':
              this.formData.guize = 4
              break;

          }
        }
      },
      // 查询竞赛题目
      async getQuestionByLibraryId() {
        const response = await getQuestionByLibraryId({
          contest_id: this.contestId,
        })
        this.seleTopic = response.body
      },
      // 新增竞赛
      async addQuestionLibrary() {
        if (this.seleTopic.length === 0) {
          window.$msg('请先选择题目', 2)
          return false
        }
        let peoblemids = ''
        this.seleTopic.forEach(item => {
          if (peoblemids === '') {
            peoblemids = item.problem_id
          } else {
            peoblemids = peoblemids + ',' + item.problem_id
          }
        })
        switch (this.formData.guize) {
          case 1:
            this.formData.guize = 'ACM'
            break;
          case 2:
            this.formData.guize = 'OI'
            break;
          case 3:
            this.formData.guize = 'IOI'
            break;
          case 4:
            this.formData.guize = 'CA'
            break;

        }
        this.langmaskValue = ''
        this.formData.language.forEach((element, index) => {
          if (index === this.formData.language.length - 1) {
            this.langmaskValue += element
          } else {
            this.langmaskValue += element + ','
          }
        })
        const response = await addQuestionLibrary({
          title: this.formData.title,
          problem_ids: peoblemids,
          pattern: '竞赛',
          langmask: this.langmaskValue,
          startTime: this.checked ? '0001-01-01 00:00:00' : this.formData.contestTime[0],
          endTime: this.checked ? '9999-12-31 23:59:59' : this.formData.contestTime[1],
          isPrivate: this.formData.opentype === 1 ? '1' : '0',
          raceRule: this.formData.guize,
          isVerify: this.formData.opentype === 3 ? 'Y' : 'N',
          defunct: 'Y',
          description: this.contestContent,
          startEnrollTime: this.applychecked ? '0001-01-01 00:00:00' : this.formData.applyTime[0],
          endEnrollTime: this.applychecked ? '9999-12-31 23:59:59' : this.formData.applyTime[1],
        })
        if (response && response.state === 'success') {
          window.$msg('新增成功')
          this.active = 2
        }
      },

      openDialog() {
        this.active = 0
        this.checked = false
        this.applychecked = false
        this.contestContent = ''
        this.seleTopic = []
        this.formData = {
          guize: '',
          opentype: '',
          title: "",
          applyTime: [],
          contestTime: [],
          language: []
        }
        if (this.copyType) {
          this.categoryTitle = '复制竞赛'
          this.getContextInfo()
          this.getQuestionByLibraryId()
        } else {
          this.categoryTitle = '新增竞赛'
        }
      },

      finishCreate() {
        this.$emit('close')
        this.$emit('updateView')
      },
      // 关闭弹窗
      close() {
        this.$emit('close')
      },
      next() {
        if (this.active === 0) {
          if (this.checked) {
            this.formData.contestTime = []
            this.formData.contestTime.push('0001-01-01 00:00:00')
            this.formData.contestTime.push('9999-12-31 23:59:59')
          }

          if (this.applychecked) {
            this.formData.applyTime = []
            this.formData.applyTime.push('0001-01-01 00:00:00')
            this.formData.applyTime.push('9999-12-31 23:59:59')
          }
          this.$refs["formData"].validate(valid => {
            if (valid) {
              this.active = 1
              this.getQuestionList()
              this.getTags()
              this.oldList = this.seleTopic.map(v => v.problem_id)
              this.newList = this.oldList.slice()
              this.$nextTick(() => {
                this.setSort()
              })
            }
          })
        }
      },
      pre() {
        if (this.active-- < 0) this.active = 0;
      },
      // 遍历树形结构
      getTreeArray(data, val) {
        for (let i in data) {
          if (data[i].id === val[0]) {
            if (data[i].pid !== '') {
              this.formData.selected.unshift(data[i].pid)
              this.getTreeArray(this.categoryList, this.formData.selected)
            }
          } else {
            if (data[i].hasOwnProperty('children')) {
              this.getTreeArray(data[i].children, val)
            }
          }
        }
      },

      // 选择
      handleChange(val) {
        this.formData.selected = val
      },

      // 选择标签
      handleSeleTag(tagId) {
        let problemIds = ''
        problemIds = tagId[0]
        for (let i = 1; i < tagId.length; i++) {
          problemIds = problemIds + ',' + tagId[i]
        }
        if (problemIds === undefined) {
          this.queryData.tagId = null
        } else {
          this.queryData.tagId = problemIds
        }
      },

      // 获取题目列表
      async getQuestionList() {
        const res = await getQuestionList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          defunct: 'N',
          title: this.queryData.select === '1' ? this.queryData.inputVal : '',
          problem_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
          tag_ids: this.queryData.tagId
        })
        this.pagination.total = res.body.total
        for (let i = 0; i < res.body.list.length; i++) {
          res.body.list[i].choosetype = 0
        }
        this.total = res.body.total
        this.dataList = res.body.list
        for (let i = 0; i < this.seleTopic.length; i++) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (this.seleTopic[i].problem_id === this.dataList[j].problem_id) {
              this.dataList[j].choosetype = 1
            }
          }
        }
      },

      // 获取启用的标签
      async getTags() {
        const res = await getTags(
          {
            pageNum: 1,
            pageSize: 10000,
            businessType: 'oj'
          }
        )
        this.tagList = res.body
      },

      jointArr(row) {
        let tagArr = []
        tagArr = row.split(',')
        return tagArr
      },

      // 重置
      reset() {
        this.queryData = {
          inputVal: '',
          select: '1',
          seleTag: '',
          tagId: ''
        }
      },
      // 查询题目
      search() {
        if (this.queryData.select === '2') {
          let reg = /^[0-9]*$/
          if (!reg.test(this.queryData.inputVal)) {
            window.$msg('请输入正确的id', 2)
            return false
          }
        }
        this.pagination.currentPage = 1
        this.getQuestionList()
      },

      handleSeleChange(val) {
        this.multipleSelection = val
      },
      // 添加题
      addQues(row, index) {
        this.dataList[index].choosetype = 1
        this.seleTopic.push(row)
      },
      // 删除
      deleteItem(row) {
        this.$confirm('是否删除此题目', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            for (let i = 0; i < this.dataList.length; i++) {
              if (this.dataList[i].problem_id === row.problem_id) {
                this.dataList[i].choosetype = 0
              }
            }
            for (let i = 0; i < this.seleTopic.length; i++) {
              if (this.seleTopic[i].problem_id === row.problem_id) {
                this.seleTopic.splice(i, 1)
              }
            }
            window.$msg('删除成功')
          })
          .catch(() => {
            return false
          })
      },
      // 批量添加数据
      enableAccount() {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let addflag = true
          for (let j = 0; j < this.seleTopic.length; j++) {
            if (
              this.multipleSelection[i].problem_id === this.seleTopic[j].problem_id
            ) {
              addflag = false
              break
            }
          }
          if (addflag) {
            for (let j = 0; j < this.dataList.length; j++) {
              if (
                this.multipleSelection[i].problem_id ===
                this.dataList[j].problem_id
              ) {
                this.dataList[j].choosetype = 1
              }
            }
            this.seleTopic.push(this.multipleSelection[i])
          }
          addflag = true
        }
        this.$refs.multipleTable.clearSelection()
      },

      // 批量删除
      disabledAccount() {
        let deleCurrnt = 0
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let addflag = true
          for (let j = 0; j < this.seleTopic.length; j++) {
            if (
              this.multipleSelection[i].problem_id === this.seleTopic[j].problem_id
            ) {
              addflag = false
              deleCurrnt = j
              break
            }
          }
          if (!addflag) {
            for (let k = 0; k < this.dataList.length; k++) {
              if (
                this.multipleSelection[i].problem_id ===
                this.dataList[k].problem_id
              ) {
                this.dataList[k].choosetype = 0
              }
            }
            this.seleTopic.splice(deleCurrnt, 1)
          }
          addflag = true
        }
        this.$refs.multipleTable.clearSelection()
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getQuestionList()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getQuestionList()
      },


    }
  }
</script>

<style lang="scss" scoped>
  .footerparent {
    display: flex;
    justify-content: center;
  }
  /deep/ .label-item-left {
    margin-top: 20px;
  }
  .haschoose {
    margin-top: 20px;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    padding-top: 0px;
  }
  .tag {
    display: inline-block;
    color: #fff;
    border-radius: 17px 20px 0px 17px;
    padding: 3px 15px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  @import "../../../style/table.scss";
  @import "../../../style/dialog.scss";
</style>
